export const DirectionIcon = ({ fill }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      width='24'
    >
      <path
        fill={fill}
        d='M8 15h2v-3h3.5v2.5L17 11l-3.5-3.5V10H9q-.425 0-.712.287Q8 10.575 8 11Zm4 7q-.375 0-.738-.15-.362-.15-.662-.45l-8-8q-.3-.3-.45-.662Q2 12.375 2 12t.15-.738q.15-.362.45-.662l8-8q.3-.3.662-.45Q11.625 2 12 2t.738.15q.362.15.662.45l8 8q.3.3.45.662.15.363.15.738t-.15.738q-.15.362-.45.662l-8 8q-.3.3-.662.45-.363.15-.738.15Zm-4-6 4 4 8-8-8-8-8 8Zm4-4Z'
      />
    </svg>
  )
}
